import { combineReducers } from "redux";

import loginReducer from "./login/reducer";
import forgotPasswordReducer from "./forgot-password/reducer";
import resetPasswordReducer from "./reset-password/reducer";
import resetPasswordStatusReducer from "./reset-password/statusReducer";
import ticketsReducer from "./my-tickets/reducer";
import ticketsByNumberReducer from "./tickets-by-number/reducer";
import incidenceReducer from "./request-incidence/reducer";
import customerProfileReducer from "./customer-profile/reducer";
import incidenceCategoryReducer from "./request-incidence-category/reducer";
import incidenceChannelReducer from "./request-incidence-channel/reducer";
import commentTiketsReducer from "./request-comments-tickets/reducer";
import listCommentTicketsReducer from "./request-list-comments/reducer";
import actionPostUpdateApnReducer from "./request-upadate-apn/reducer";
import actionPostBarringActionReducer from "./resuqest-barring-action/reducer";
import actionSuspendLineReducer from "./request-suspend-line/reducer";
import actionPostUnbarringActionReducer from "./request-unbarring-action/reducer";
import getActionImeiReducer from "./request-imei/reducer";
import eventsReducer from "./events/reducer";
import lockImeiReducer from "./request-lock-imei/reducer";
import unlockImeiReducer from "./request-unlock-imei/reducer";
import supplementaryServiceReducer from "./get-supplementary-service/reducer";
import changeSupplementaryServiceReducer from "./post-supplementary-service/reducer";
import nirReducer from "./request-get-nir/reducer";
import changeNirReducer from "./post-change-nir/reducer";
import changeSimReducer from "./request-change-sim/reducer";
import statusListReducer from "./get-status/reducer";
import changeStatusReducer from "./change-status/reducer";
import userListReducer from "./get-user-list/reducer";
import changeUserReducer from "./change-user/reducer";
import ticketByIdReducer from "./get-ticket-by-id/reducer";
import resumeLineActionReducer from "./request-resume-line/reducer";

import portabilityReducer from "./portability/reducer";
import leadReducer from "./lead/reducer";
import leadsReducer from "./leads/reducer";

import linksReducer from "./links/reducer";

import municipalityReducer from "./sepomex-municipality/reducer";
import neighborhoodReducer from "./sepomex-neighborhood/reducer";

import offersReducer from "./offers/reducer";
import checkImeiReducer from "./check-imei/reducer";

import backofficeLeadStatusReducer from "./backoffice-lead-status/reducer";
import backofficeStatusReducer from "./backoffice-status/reducer";

import putLeadReducer from "./put-lead/reducer";

import checkPaymentStatusReducer from "./get-check-payment-status/reducer";

import leadAssignmentReducer from "./lead-assignment/reducer";

import leadsByCampaingReducer from "./leads-by-campaing/reducer";
import ordersByProductReducer from "./orders-by-product/reducer";

import getCampaingReducer from "./get-campaing/reducer";
import getChannelReducer from "./get-channel/reducer";

import campaignChannelReducer from "./campaign-channel/reducer";

import channelCreatedLeadReducer from "./channel-lead/reducer";

import createLeadReducer from "./create_lead/reducer";

import patchLeadReducer from "./patch-lead/reducer";

import landingListReducer from "./get-landing_list/reducer";

import leadStatusListObjReducer from "./get-lead-status-list/reducer";

import activationsAndDeactivationsReducer from "./get-activations-and-deactivations/reducer";

import prepaidByDayReducer from "./get-prepaid-by-date-kpi/reducer";

import prepaidByProductReducer from "./get-prepaid-by-product-kpi/reducer";

import saleOrdersReducer from "./sales-orders/reducer";

import createIncidenceReducer from "./create_incidence/reducer";
import saleOrderDetailReducer from "./sales-detail-order/reducer";

import generalTicketsReducer from "./tickets/reducer";
import saleOrderEsimQrReducer from "./esim-qr/reducer";

import mySalesReducer from "./my-sales/reducer";
import mySalesDetailsReducer from "./my-sales-details/reducer";
import mySalesExecutiveReducer from "./reports-executive/reducer";
import mySalesExecutiveDetailsReducer from "./my-sales-detail-executive/reducer";

import postBindAssistedOrderReducer from "./post-bind-assisted-order/reducer";
import dailyRefillsPerProductReducer from "./kpi_daily_refills_per_product/reducer";
import prepaidsBySubDistributorReducer from "./total-prepaids-by-sub-distributor/reducer";
import ConsultingcheckImeiReducer from "./consulting-imei/reducer";

import getSalesMonthlyReducer from "./get-sales-monthly/reducer";
import getSalesWeeklyReducer from "./get-sales-weekly/reducer";
import getSalesDailyReducer from "./get-sales-daily/reducer";
import getContactPhoneReducer from "./contact-phone-report/reducer";
import getEmailReportReducer from "./email-report/reducer";
import ipRestrictionReducer from "./ip-restriction/reducer";

import changeSimToEsimReducer from "./change-sim-esim/reducer";
import changeEsimToSimReducer from "./change-esim-sim/reducer";
import transferSalesReducer from "./transfer-sales/reducer";
import offersForSimPurchaseReducer from "./offers-for-sim-purchase/reducer";
import listTransferSalesReducer from "./list-transfer-sales/reducer";
import detailTransferSalesReducer from "./detail-transfer-sales/reducer";
import processTransferSalesReducer from "./process-transfer-sales/reducer";

import getGiftOrdersReducer from "./gift-orders/get-gift-orders/getGiftOrdersReducer";
import getGiftOrdersDetailReducer from "./gift-orders/get-gift-orders-detail/getGiftOrdersDetailReducer";

import netPayReducer from "./list-transactions-netpay/reducer";
import simActivationReducer from "./sim-activation/reducer";
import detailTransactionsNetpayReducer from "./detail-transactions-netpay/reducer";
import rechargeHistoryReducer from "./recharge-history/reducer";
import listTransactionsSTPReducer from "./list-transfer-stp/reducer";
import detailsTransactionsSTPReducer from "./detail-transactions-stp/reducer";

import atnPortability from "./atn-portability/reducer";
import makePrepaidReducer from "./makePrepaid/reducer";
import productsPrepaid from "./products_prepaid/reducer";
import listTransactionsTokuReducer from "./list-transactions-toku/reducer";
import detailsTransactionsTokuReducer from "./detail-transactions-toku/reducer";
import detailTransactionFraudReducer from "./detail-transaction-fraud/reducer";
import detailTransactionChargebackReducer from "./detail-transaction-chargeback/reducer";
import reportTransactionsFraudReducer from "./report-transaction-fraud/reducer";
import reportTransactionsChargebackReducer from "./report-transaction-chargeback/reducer";
import listSimIpbReducer from "./list-ipb/reducer";
import listGiftSimIpbReducer from "./gift-sim-list-ipb/reducer";
import prepaidReducer from "./prepaid-history/reducer";
import detailListSimIpbReducer from "./detail-list-sim-ipb/reducer";
import detailGiftSimIpbReducer from "./detail-gift-sim-ipb/reducer";
import ipbInvoiceListReducer from "./ipb-invoice-list/reducer";
import postGenerateInvoicesReducer from "./generate-invoice/reducer";
import editLegalDataReducer from "./legal-Data-edit/reducer";
import openpayReducer from "./list-transactions-openpay/reducer";
import detailTransactionsOpenpayReducer from "./detail-transactions-openpay/reducer";
import ipbDirectoryListingReducer from "./ipb-directory-listing/reducer";
import ipbDirectoryDetailReducer from "./ipb-directory-detail/reducer";
import ipbDirectoryModerationListingReducer from "./ipb-moderacion-listing/reducer";
import ipbDirectoryModerationDetailReducer from "./ipb-moderacion-detail/reducer";
import ipbDirectoryModerationReducer from "./handle-store-moderation/reducer";
import registeredStoresReducer from "./register-mtc-id/reducer";
import editStoresReducer from "./edit-mtc-id/reducer";
import directoryStoreExistsReducer from "./directory-stores-exists/reducer";
import directoryStoreOmvsReducer from "./directory-stores-omvs/reducer";
import zipCodeAddressStoreReducer from "./zipcode-address-store/reducer";
import neighborhoodLocationReducer from "./neighborhood-location/reducer";
import simGiftOrdersReducer from "./email-sim-gift-ipb/reducer";
import preRegisterGiftSimIpbReducer from "./pre-register-gift-sim-ipb/reducer";

export default combineReducers({
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    customerProfile: customerProfileReducer,
    resetPassword: resetPasswordReducer,
    resetPasswordStatus: resetPasswordStatusReducer,
    ticketsReducer: ticketsReducer,
    ticketsByNumber: ticketsByNumberReducer,
    incidence: incidenceReducer,
    incidenceCategory: incidenceCategoryReducer,
    incidenceChannel: incidenceChannelReducer,
    commentTikets: commentTiketsReducer,
    listCommentTickets: listCommentTicketsReducer,
    actionPostUpdateApn: actionPostUpdateApnReducer,
    actionPostBarringAction: actionPostBarringActionReducer,
    actionPostUnbarringAction: actionPostUnbarringActionReducer,
    actionSuspendLine: actionSuspendLineReducer,
    getActionImei: getActionImeiReducer,
    events: eventsReducer,
    lockImei: lockImeiReducer,
    unlockImei: unlockImeiReducer,
    supplementaryService: supplementaryServiceReducer,
    changeSupplementaryService: changeSupplementaryServiceReducer,
    nir: nirReducer,
    changeSim: changeSimReducer,
    changeNir: changeNirReducer,
    statusList: statusListReducer,
    changeStatusTicket: changeStatusReducer,
    userList: userListReducer,
    changeUser_: changeUserReducer,
    ticketById: ticketByIdReducer,
    resumeLineAction: resumeLineActionReducer,
    portability: portabilityReducer,
    lead: leadReducer,
    leads: leadsReducer,

    links: linksReducer,

    municipality: municipalityReducer,
    neighborhood: neighborhoodReducer,
    offers: offersReducer,
    checkImei: checkImeiReducer,
    backofficeLeadStatus: backofficeLeadStatusReducer,
    backofficeStatus: backofficeStatusReducer,
    putLead: putLeadReducer,

    prepaidByDay: prepaidByDayReducer,

    prepaidByProduct: prepaidByProductReducer,

    checkPaymentStatus: checkPaymentStatusReducer,

    leadAssignment: leadAssignmentReducer,

    leadsByCampaing: leadsByCampaingReducer,
    ordersByProduct: ordersByProductReducer,

    activationsAndDeactivation: activationsAndDeactivationsReducer,

    getCampaingObj: getCampaingReducer,

    getChannelObj: getChannelReducer,

    campaignChannel: campaignChannelReducer,

    channelCreatedLead: channelCreatedLeadReducer,

    createLeadObj: createLeadReducer,

    patchLeadObj: patchLeadReducer,

    landingListObj: landingListReducer,

    leadStatusListObj: leadStatusListObjReducer,

    saleOrders: saleOrdersReducer,

    createIncidenceObj: createIncidenceReducer,
    saleOrderDetail: saleOrderDetailReducer,
    generalTickets: generalTicketsReducer,

    saleOrderEsimQr: saleOrderEsimQrReducer,

    mySales: mySalesReducer,

    mySalesDetails: mySalesDetailsReducer,

    postBindAssistedOrder: postBindAssistedOrderReducer,
    mySalesExecutive: mySalesExecutiveReducer,
    mySalesExecutiveDetails: mySalesExecutiveDetailsReducer,

    dailyRefillsPerProduct: dailyRefillsPerProductReducer,
    prepaidsBySubDistributor: prepaidsBySubDistributorReducer,
    ConsultingcheckImei: ConsultingcheckImeiReducer,

    getSalesMonthly: getSalesMonthlyReducer,
    getSalesWeekly: getSalesWeeklyReducer,
    getSalesDaily: getSalesDailyReducer,

    getContactPhone: getContactPhoneReducer,
    getEmailReport: getEmailReportReducer,
    ipRestriction: ipRestrictionReducer,
    changeEsimToSim: changeEsimToSimReducer,
    changeSimToEsim: changeSimToEsimReducer,

    getGiftOrders: getGiftOrdersReducer,
    getGiftOrdersDetail: getGiftOrdersDetailReducer,

    transferSales: transferSalesReducer,
    ofersSimPurchase: offersForSimPurchaseReducer,
    listTransferSales: listTransferSalesReducer,
    detailTransferSales: detailTransferSalesReducer,
    processTransferSales: processTransferSalesReducer,
    netPay: netPayReducer,
    simActivation: simActivationReducer,
    detailTransactionsNetpay: detailTransactionsNetpayReducer,
    rechargeHistory: rechargeHistoryReducer,
    listTransactionsSTP: listTransactionsSTPReducer,
    detailsTransactionsSTP: detailsTransactionsSTPReducer,

    atnPortability: atnPortability,
    makePrepaid: makePrepaidReducer,
    productsPrepaid: productsPrepaid,
    listTransactionsToku: listTransactionsTokuReducer,
    detailsTransactionsToku: detailsTransactionsTokuReducer,

    detailTransactionFraud: detailTransactionFraudReducer,
    detailTransactionChargeback: detailTransactionChargebackReducer,
    reportTransactionsFraud: reportTransactionsFraudReducer,
    reportTransactionsChargeback: reportTransactionsChargebackReducer,
    listSimIpb: listSimIpbReducer,
    listGiftSimIpb: listGiftSimIpbReducer,
    prepaid: prepaidReducer,
    detailListSimIpb: detailListSimIpbReducer,
    detailGiftSimIpb: detailGiftSimIpbReducer,
    ipbInvoiceList: ipbInvoiceListReducer,
    generateInvoices: postGenerateInvoicesReducer,
    responseEditLegalData: editLegalDataReducer,
    listOpenpay: openpayReducer,
    detailTransactionsOpenpay: detailTransactionsOpenpayReducer,
    responseIpbDirectoryListing: ipbDirectoryListingReducer,
    responseIpbDirectoryDetail: ipbDirectoryDetailReducer,
    responseIpbDirectoryModerationListing: ipbDirectoryModerationListingReducer,
    responseIpbDirectoryModerationDetail: ipbDirectoryModerationDetailReducer,
    responseIpbDirectoryModeration: ipbDirectoryModerationReducer,
    responseRegisteredStores: registeredStoresReducer,
    responseEditStores: editStoresReducer,
    responseDirectoryStoreExists: directoryStoreExistsReducer,
    responseDirectoryStoreOmvsReducer: directoryStoreOmvsReducer,
    responseZipCodeAddressStoreReducer: zipCodeAddressStoreReducer,
    responseNeighborhoodLocation: neighborhoodLocationReducer,
    simGiftOrders: simGiftOrdersReducer,
    preRegisterSimGiftIpb: preRegisterGiftSimIpbReducer,
});
