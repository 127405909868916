import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export const usePreRegisterGiftSimIpb = ({
    preRegisterGiftSimIpbAction,
    preRegisterGiftSimIpb,
    cleanPreRegisterGiftSimIpb,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        if (preRegisterGiftSimIpb?.status === 200) {
            setLoading(false);
        } else if (preRegisterGiftSimIpb?.payload?.response?.status > 200) {
            setDialogMessage(
                preRegisterGiftSimIpb?.payload?.response?.data?.detail
            );
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [preRegisterGiftSimIpb]);

    const onSubmit = async (csvFile) => {
        setLoading(true);
        try {
            await preRegisterGiftSimIpbAction(csvFile);
            setDialogMessage("Pre registro completado exitosamente!");
            setIsDialogOpen(true);
        } catch {
            setDialogMessage("Error al realizar el pre registro");
            setIsDialogOpen(true);
        }
        setErrorMessage("");
    };

    const handleClose = () => {
        if (isDialogOpen) {
            setIsDialogOpen(false);
        } else {
            setIsDialogOpen(false);
        }
    };

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        errorMessage,
        setErrorMessage,
        setError,
        error,
    };
};
