import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import CustomDialog from "widgets/component/CustomDialog";

const PreRegisterGiftSimsForm = ({ onSubmit }) => {
    const [csvFile, setCsvFile] = useState(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.name.endsWith(".csv")) {
            setCsvFile(file);
        } else {
            setDialogMessage("Solo se aceptan archivos .csv");
            setIsDialogOpen(true);
        }
    };

    const handleFileRemove = () => {
        setCsvFile(null);
    };

    const handleProcessClick = () => {
        if (csvFile) {
            onSubmit(csvFile);
        }
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <Box paddingLeft={5}>
            <Box mt={3} display="flex" alignItems="center" gap={2}>
                <Typography variant="body1" width={300}>
                    {csvFile
                        ? csvFile.name
                        : "Carga un archivo .csv para continuar con el pre-registro"}
                </Typography>
                {!csvFile && (
                    <Button
                        variant="contained"
                        component="label"
                        color="primary"
                    >
                        Cargar archivo
                        <input
                            type="file"
                            accept=".csv"
                            hidden
                            onChange={handleFileChange}
                        />
                    </Button>
                )}

                {csvFile && (
                    <>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleFileRemove}
                        >
                            Eliminar archivo
                        </Button>
                    </>
                )}
            </Box>

            <Box mt={2}>
                <Button
                    variant="contained"
                    disabled={!csvFile}
                    sx={{
                        backgroundColor: csvFile ? "primary.main" : "grey.400",
                    }}
                    onClick={handleProcessClick}
                >
                    Procesar Pre registro
                </Button>
            </Box>
            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleCloseDialog,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </Box>
    );
};

export default PreRegisterGiftSimsForm;
