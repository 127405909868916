import React, { useState, useEffect } from "react";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import { useForm } from "react-hook-form";
import { Chip, Button } from "@mui/material";
import ActionsMenu from "utils/ActionsMenuDatagrid";

export const ContentRechargeHistoryHooks = ({
    ticketId,
    rechargeHistoryAction,
    rechargeHistory,
    rechargeHistoryClean,
    getPrepaid,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    transactionId,
    onClose,
    prepaid,
    // onEnter,
}) => {
    const { response } = rechargeHistory || {};
    const { pagination, result } = response || {};
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDialogOpenResponse, setIsDialogOpenResponse] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [action, setAction] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [openActionFraud, setOpenActionFraud] = useState(false);
    const [error, setError] = useState(false);
    const [loadingFraudAndChargeback, setLoadingFraudAndChargeback] =
        useState(false);
    const [descriptionTransaction, setDescriptionTransaction] = useState("");
    const [isError, setIsError] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (dates) => {
        setLoading(true);
        const payload = {
            dates,
            ticketId,
            page: page === 0 ? 1 : page,
            size,
        };
        rechargeHistoryAction(payload);
        localStorage.setItem(
            "rechargeHistoryDatesPayload",
            JSON.stringify(dates)
        );
    };

    useEffect(() => {
        // setLoading(true);
        if (transactionId) {
            setIsDialogOpenDetails(true);
            getPrepaid(transactionId);
        }
    }, [transactionId, setLoading, getPrepaid]);

    useEffect(() => {
        // setLoading(true);
        if (prepaid?.status === 200) {
            // setIsDialogOpenDetails(true);

            setLoading(false);
            // onEnter();
        }
    }, [prepaid, transactionId]);

    useEffect(() => {
        const dates = JSON.parse(
            localStorage.getItem("rechargeHistoryDatesPayload")
        );
        if (page !== 0 && dates) {
            setLoading(true);
            const payload = {
                dates,
                ticketId,
                page,
                size,
            };
            rechargeHistoryAction(payload)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, size]);

    const mappedRows =
        result?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleChangePageSize = (newSize) => {
        setSize(newSize);
        setPage(1);
    };

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const getStatusColor = (paymentStatus) => {
        switch (paymentStatus) {
            case "SUCCESS":
                return "green";
            case "FAIL":
                return "red";
            case "Review":
                return "Orange";
            default:
                return "purple";
        }
    };

    const translationMap = {
        Requested: "Transacción registrada",
        SUCCESS: "Transacción exitosa",
        FAIL: "Falló al procesar el pago",
        Review: "En espera de revisión",
        ERROR: "Error al procesar el pago",
    };

    const handleOpenPopUp = (action) => {
        setAction(action);
        setOpenActionFraud(true);
    };

    const handleIdButtonClick = (id) => {
        setSelectedRowId(id);
        // setIDorder(id);
        if (id) {
            setIsDialogOpenDetails(true);
            getPrepaid(id);
        } else {
            console.error("id no existe   ", id);
        }
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        { field: "direct_number", headerName: "Télefono", width: 150 },
        {
            field: "product",
            headerName: "Paquete",
            width: 200,
        },
        {
            field: "created",
            headerName: "Fecha de creación",
            width: 200,

            headerAlign: "center",
            renderCell: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },
        {
            field: "offer_expires_at",
            headerName: "Fecha de vencimiento",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => {
                const date = params.row.offer_expires_at;
                return date ? convertToMexicoTime(date) : "Sin fecha";
            },
        },
        {
            field: "status",
            headerName: "Estado de pago",
            width: 220,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Chip
                    label={
                        translationMap[params.row.status] || params.row.status
                    }
                    style={{
                        backgroundColor: getStatusColor(params.row.status),
                        color: "white",
                    }}
                />
            ),
        },
        {
            field: "combined_distributor",
            headerName: "Distribuidor",
            width: 400,
            renderCell: (params) => {
                const distributor = params.row.distributor || "";
                const distributorUser = params.row.distributor_user || "";
                return `${distributor} - ${distributorUser}`;
            },
        },
        {
            field: "custom_combined_message",
            headerName: "Mensaje",
            width: 500,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const responseMessage = params.row.response_message || "";
                const responseCode = params.row.response_code || "";
                return `${responseMessage}  ${responseCode}`;
            },
        },
        {
            field: "source",
            headerName: "Fuente",
            width: 200,
        },
        {
            field: "actions",
            headerName: "Acciones",
            width: 200,
            headerAlign: "center",
            renderCell: (params) => (
                <ActionsMenu
                    onFraud={() => handleOpenPopUp("fraud", params.id)}
                    onChargeback={() =>
                        handleOpenPopUp("chargeback", params.id)
                    }
                />
            ),
        },
    ];

    const handleClose = () => {
        console.log("Cerrando");
        setIsDialogOpenResponse(false);
        setIsDialogOpenDetails(false);
        rechargeHistoryClean();
        localStorage.removeItem("rechargeHistoryDatesPayload");
        if (transactionId) {
            onClose();
        }
    };

    const handleCloseFraudChargeback = () => {
        setOpenActionFraud(false);
        setDescriptionTransaction("");
        setError(false);
        setDialogMessage("");

        if (action === "fraud") {
            cleanReportTransactionsFraud();
        } else if (action === "chargeback") {
            cleanReportPrepaidChargebackDetails();
        }
    };

    const handleConfirm = () => {
        if (descriptionTransaction === "") {
            setError(true);
        } else {
            setError(false);
            if (action === "fraud") {
                handleFraudPrevention(selectedRowId);
            } else if (action === "chargeback") {
                handleChargebackPrevention(selectedRowId);
            }
        }
    };

    const handleChargebackPrevention = (data) => {
        setLoadingFraudAndChargeback(true);
        const payload = {
            txn_payment_id: data,
            descriptionTransaction,
            payment_provider_name: "STP",
        };
        actionReportTransactionsChargeback({ ...payload });
    };

    const handleFraudPrevention = (data) => {
        setLoadingFraudAndChargeback(true);
        const payload = {
            txn_payment_id: data,
            descriptionTransaction,
            payment_provider_name: "STP",
        };
        actionReportTransactionsFraud({ ...payload });
    };

    useEffect(() => {
        if (rechargeHistory?.status === 200) {
            setLoading(false);
        } else if (rechargeHistory?.response?.response?.status > 200) {
            setDialogMessage(rechargeHistory?.response?.response?.data?.detail);
            setIsDialogOpenResponse(true);
            setLoading(false);
        } else {
            console.log("Ninguna condición cumplida");
        }
    }, [rechargeHistory, onClose]);

    useEffect(() => {
        if (reportTransactionsChargeback?.status === 200) {
            setDialogMessage("La operación se completó con éxito.");
            setIsError(false);
            setLoadingFraudAndChargeback(false);
            getPrepaid(setSelectedRowId);
        } else if (
            reportTransactionsChargeback?.payload?.response?.status > 200
        ) {
            setLoadingFraudAndChargeback(false);
            setDialogMessage(
                reportTransactionsChargeback?.payload?.response?.data?.detail ||
                    "Ha ocurrido un error."
            );
            setIsError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTransactionsChargeback]);

    useEffect(() => {
        if (reportTransactionsFraud?.status === 200) {
            setDialogMessage("La operación se completó con éxito.");
            setIsError(false);
            setLoadingFraudAndChargeback(false);
            getPrepaid(setSelectedRowId);
        } else if (reportTransactionsFraud?.payload?.response?.status > 200) {
            setLoadingFraudAndChargeback(false);
            setDialogMessage(
                reportTransactionsFraud?.payload?.response?.data?.detail ||
                    "Ha ocurrido un error."
            );
            setIsError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportTransactionsFraud]);

    return {
        Columns,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        onSubmit,
        mappedRows,
        register,
        handleSubmit,
        size,
        errors,
        page,
        isDialogOpenResponse,
        dialogMessage,
        setIsDialogOpenResponse,
        loading,
        handleClose,
        isDialogOpenDetails,
        handleOpenPopUp,
        setOpenActionFraud,
        openActionFraud,
        handleCloseFraudChargeback,
        error,
        loadingFraudAndChargeback,
        handleConfirm,
        setDescriptionTransaction,
        setError,
        isError,
        setLoading,
    };
};
