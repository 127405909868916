import React from "react";
import { connect } from "react-redux";
import { Grid2 as Grid, Typography } from "@mui/material";
import { usePreRegisterGiftSimIpb } from "./hooks/usePreRegisterGiftSimIpb";
import CustomDialog from "widgets/component/CustomDialog";
import PreRegisterGiftSimsForm from "./components/PreRegisterGiftSimsForm";
import { preRegisterGiftSimIpbAction } from "_models/redux/pre-register-gift-sim-ipb/action";
import { cleanPreRegisterGiftSimIpb } from "_models/redux/pre-register-gift-sim-ipb/clean-action";

const PagePreRegisterGiftSimIpb = ({
    preRegisterGiftSimIpbAction,
    preRegisterGiftSimIpb,
    cleanPreRegisterGiftSimIpb,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        isDialogOpen,
        dialogMessage,
        handleClose,
        errorMessage,
    } = usePreRegisterGiftSimIpb({
        preRegisterGiftSimIpbAction,
        preRegisterGiftSimIpb,
        cleanPreRegisterGiftSimIpb,
        onClose,
    });

    return (
        <>
            <Grid>
                <h3>Pre registro de SIMs de regalo IPB</h3>
            </Grid>
            <hr />
            <Grid marginBottom={4}>
                <PreRegisterGiftSimsForm onSubmit={onSubmit} />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <hr />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    preRegisterGiftSimIpb: state?.preRegisterGiftSimIpb,
});

const mapDispatchToProps = {
    preRegisterGiftSimIpbAction,
    cleanPreRegisterGiftSimIpb,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PagePreRegisterGiftSimIpb);
