import {
    PRE_REGISTER_SIM_GIFT_IPB_ERROR,
    PRE_REGISTER_SIM_GIFT_IPB,
    CLEAN_PRE_REGISTER_GIFT_SIM_IPB,
    CLEAN_PRE_REGISTER_GIFT_SIM_IPB_ERROR,
} from "_models/redux/types";

const initialState = {
    orders: [],
    total: 0,
    page: 1,
    limit: 10,
    loading: false,
    error: null,
};

const preRegisterGiftSimIpbReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRE_REGISTER_SIM_GIFT_IPB:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case PRE_REGISTER_SIM_GIFT_IPB_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CLEAN_PRE_REGISTER_GIFT_SIM_IPB:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_PRE_REGISTER_GIFT_SIM_IPB_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
};

export default preRegisterGiftSimIpbReducer;
