import {
    PRE_REGISTER_SIM_GIFT_IPB_ERROR,
    PRE_REGISTER_SIM_GIFT_IPB,
} from "_models/redux/types";
import axiosTokenInstance from "services/api/backoffice";

export const preRegisterGiftSimIpbAction = (csvFile) => async (dispatch) => {
    const url = `/api/ipb/`;

    try {
        const formData = new FormData();
        formData.append("csv", csvFile);

        const response = await axiosTokenInstance.put(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const { data, status } = response || {};

        dispatch({
            method: "put",
            payload: data || {},
            status: status || null,
            type: PRE_REGISTER_SIM_GIFT_IPB,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            method: "put",
            payload: e || {},
            status: e?.response?.status || null,
            type: PRE_REGISTER_SIM_GIFT_IPB_ERROR,
        });
        throw e;
    }
};
