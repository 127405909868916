import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { cleantIncidence } from "_models/redux/request-incidence/clean_action";
import { postIncidence } from "_models/redux/request-incidence/action";
import { getIncidenceCategory } from "_models/redux/request-incidence-category/action";
import { getIncidenceChannel } from "_models/redux/request-incidence-channel/action";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

const Modal = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { name, last_name, mothers_last_name, directNumber } = state || {};

    const {
        setOpenModal,
        cleantIncidence,
        incidence,
        incidenceCategory,
        incidenceChannel,
    } = props || {};

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [dropdownOptionsChannels, setDropdownOptionsChannels] = useState([]);
    const [clientName, setClientName] = useState(name);
    const [clientLastName, setClientLastName] = useState(last_name);
    const [clientMothersLastName, setClientMothersLastName] =
        useState(mothers_last_name);
    const [channelId, setChannelId] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        cleantIncidence();
        props.getIncidenceCategory();
        props.getIncidenceChannel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const registerOptions = {
        clientName: {
            required: "Nombre es requerido",
            minLength: {
                value: 3,
                message: "mínimo 3 caracteres",
            },
        },
        clientLastName: {
            required: "Apellido es requerido",
            minLength: {
                value: 3,
                message: "mínimo 3 caracteres",
            },
        },
        clientMothersLastName: {
            required: "Apellido Materno es requerido",
            minLength: {
                value: 3,
                message: "mínimo 3 caracteres",
            },
        },
        channelId: { required: "Canal es requerido" },
        categoryId: { required: "Categoria es requerido" },
        title: {
            required: "Titutlo es requerido",
            minLength: {
                value: 10,
                message: "mínimo 10 caracteres",
            },
        },
    };
    const onSubmit = (e) => {
        // e.preventDefault();
        const post = {
            client_name: e.clientName,
            client_last_name: e.clientLastName,
            client_mothers_last_name: clientMothersLastName,
            channel_id: channelId,
            category_id: categoryId,
            status_id: 2,
            title: title,
            direct_number: directNumber,
        };
        setIsLoading(true);
        props.postIncidence(post);
    };

    useEffect(() => {
        const { incidenceObj } = incidence || {};
        const { message, id, response } = incidenceObj || {};
        const { data } = response || {};
        const { detail } = data || {};

        setIsLoading(false);

        if (message === "success") {
            const link = "/tickets/" + id;
            navigate(link);
        } else if (message === "Request failed with status code 400") {
            Swal.fire({
                icon: "error",
                text: detail,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidence]);

    useEffect(() => {
        const { loading, incidenceCategoryObj } = incidenceCategory || {};
        const { categories } = incidenceCategoryObj || {};

        if (
            loading &&
            typeof categories !== "undefined" &&
            Array.isArray(categories)
        ) {
            if (categories.length > 0) {
                setDropdownOptions(categories);
            }
        }
    }, [incidenceCategory]);

    useEffect(() => {
        const { loading, incidenceChannelObj } = incidenceChannel || {};

        const { channels } = incidenceChannelObj || {};

        if (
            loading &&
            typeof channels !== "undefined" &&
            Array.isArray(channels)
        ) {
            if (channels.length > 0) {
                setDropdownOptionsChannels(channels);
            }
        }
    }, [incidenceChannel]);

    useEffect(() => {
        return () => {
            cleantIncidence();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className="modalBackground">
                <Grid item xs={7} className="modalContainer">
                    <Grid container className="title">
                        <Grid item xs={6}>
                            <h4>Nueva incidencia</h4>
                        </Grid>
                        <Grid item xs={6} className="titleCloseBtn">
                            <button
                                onClick={() => {
                                    setOpenModal(false);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </Grid>
                    </Grid>
                    <Grid container direction={"row"} spacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction={"column"} rowSpacing={2}>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register(
                                            "clientName",
                                            registerOptions.clientName
                                        )}
                                        id="outlined-textarea"
                                        label="Nombre(s)"
                                        placeholder="Nombre"
                                        multiline
                                        value={clientName}
                                        onChange={(event) => {
                                            setClientName(event.target.value);
                                        }}
                                    />
                                    <span>
                                        {errors?.clientName && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.clientName.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register(
                                            "clientLastName",
                                            registerOptions.clientLastName
                                        )}
                                        id="outlined-textarea"
                                        label="Apellido paterno"
                                        placeholder="Apellido paterno"
                                        multiline
                                        onChange={(event) => {
                                            setClientLastName(
                                                event.target.value
                                            );
                                        }}
                                        value={clientLastName}
                                    />
                                    <span>
                                        {errors?.clientLastName && (
                                            <p className="required-incidence">
                                                {" "}
                                                {errors.clientLastName.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register(
                                            "clientMothersLastName",
                                            registerOptions.clientMothersLastName
                                        )}
                                        id="outlined-textarea"
                                        label="Apellido materno"
                                        placeholder="Apellido materno"
                                        multiline
                                        onChange={(event) => {
                                            setClientMothersLastName(
                                                event.target.value
                                            );
                                        }}
                                        value={clientMothersLastName}
                                    />
                                    <span>
                                        {errors?.clientMothersLastName && (
                                            <p className="required-incidence">
                                                {" "}
                                                {
                                                    errors.clientMothersLastName
                                                        .message
                                                }
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction={"column"} rowSpacing={2}>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        {...register(
                                            "channelId",
                                            registerOptions.channelId
                                        )}
                                        select
                                        id="outlined-select-currency"
                                        label="Canal"
                                        onChange={(event) => {
                                            setChannelId(event.target.value);
                                        }}
                                        value={channelId}
                                    >
                                        {dropdownOptionsChannels.map(
                                            (option) => {
                                                return (
                                                    <MenuItem
                                                        key={option.id}
                                                        value={option.id || 0}
                                                    >
                                                        {option.name}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </TextField>
                                    <span>
                                        {errors?.channelId && (
                                            <p className="required-incidence">
                                                {errors.channelId.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        {...register(
                                            "categoryId",
                                            registerOptions.categoryId
                                        )}
                                        id="outlined-select-currency"
                                        select
                                        label="Categoria"
                                        onChange={(event) => {
                                            setCategoryId(event.target.value);
                                        }}
                                        // helperText="Selecciona una categoría"
                                        value={categoryId}
                                    >
                                        {dropdownOptions.map((option) => {
                                            return (
                                                <MenuItem
                                                    key={option.id}
                                                    value={option.id || 0}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                    <span>
                                        {errors?.categoryId && (
                                            <p className="required-incidence">
                                                {errors.categoryId.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        {...register(
                                            "title",
                                            registerOptions.title
                                        )}
                                        id="outlined-textarea"
                                        label="Título"
                                        placeholder="Título de la inicidencia"
                                        multiline
                                        onChange={(event) => {
                                            setTitle(event.target.value);
                                        }}
                                        value={title}
                                    />
                                    <span>
                                        {errors?.title && (
                                            <p className="required-incidence">
                                                {errors.title.message}
                                            </p>
                                        )}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="footer">
                        <button
                            onClick={() => {
                                setOpenModal(false);
                            }}
                            id="cancelBtn"
                        >
                            Cancelar
                        </button>

                        <button type="submit">Crear</button>
                    </Grid>
                    <center> {isLoading && <h1>Cargando...</h1>}</center>

                    {/* <Grid item xs={12} className={"responseCls"}>
                    {responseMessage}
                </Grid> */}
                </Grid>
            </Grid>
        </form>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    tickets: state.tickets,
    incidence: state.incidence,
    incidenceCategory: state.incidenceCategory,
    incidenceChannel: state.incidenceChannel,
});
const mapDispatchToProps = {
    cleantIncidence,
    postIncidence,
    getIncidenceCategory,
    getIncidenceChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
