export const ACTION_DEFAULT = "ACTION_DEFAULT";
export const ACTION_DEFAULT_ERROR = "ACTION_DEFAULT_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_CLEAN = "LOGIN_CLEAN";
export const LOGIN_CLEAN_ERROR = "LOGIN_CLEAN_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_CLEAN = "FORGOT_PASSWORD_CLEAN";
export const FORGOT_PASSWORD_CLEAN_ERROR = "FORGOT_PASSWORD_CLEAN_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_CLEAN = "RESET_PASSWORD_CLEAN";
export const RESET_PASSWORD_CLEAN_ERROR = "RESET_PASSWORD_CLEAN_ERROR";

export const RESET_PASSWORD_STATUS = "RESET_PASSWORD_STATUS";
export const RESET_PASSWORD_STATUS_ERROR = "RESET_PASSWORD_STATUS_ERROR";
export const RESET_PASSWORD_STATUS_CLEAN = "RESET_PASSWORD_STATUS_CLEAN";
export const RESET_PASSWORD_STATUS_CLEAN_ERROR =
    "RESET_PASSWORD_STATUS_CLEAN_ERROR";

export const TICKETS = "TICKETS";
export const TICKETS_ERROR = "TICKETS_ERROR";
export const CLEAN_TICKETS = "CLEAN_TICKETS";
export const CLEAN_TICKETS_ERROR = "CLEAN_TICKETS_ERROR";

export const TICKETS_BY_NUMBER = "TICKETS_BY_NUMBER";
export const TICKETS_BY_NUMBER_ERROR = "TICKETS_BY_NUMBER_ERROR";

export const INCIDENCE = "INCIDENCE";
export const INCIDENCE_ERROR = "INCIDENCE_ERROR";

export const INCIDENCE_CLEAN = "INCIDENCE_CLEAN";
export const INCIDENCE_CLEAN_ERROR = "INCIDENCE_CLEAN_ERROR";

export const INCIDENCE_CATEGORY = "INCIDENCE_CATEGORY";
export const INCIDENCE_CATEGORY_ERROR = "INCIDENCE_CATEGORY_ERROR";

export const INCIDENCE_CHANNEL = "INCIDENCE_CHANNEL";
export const INCIDENCE_CHANNEL_ERROR = "INCIDENCE_CHANNEL_ERROR";

export const CUSTOMER_PROFILE = "CUSTOMER_PROFILE";
export const CUSTOMER_PROFILE_ERROR = "CUSTOMER_PROFILE_ERROR";
export const CUSTOMER_PROFILE_CLEAN = "CUSTOMER_PROFILE_CLEAN";
export const CUSTOMER_PROFILE_ERROR_CLEAN = "CUSTOMER_PROFILE_ERROR_CLEAN";
export const COMMENT_TICKETS = "COMMENT_TICKETS";
export const COMMENT_TICKETS_ERROR = "COMMENT_TICKETS_ERROR";

export const LIST_COMMENT_TICKETS = "LIST_COMMENT_TICKETS";
export const LIST_COMMENT_TICKETS_ERROR = "LIST_COMMENT_TICKETS_ERROR";

export const UPDATE_APN = "UPDATE_APN";
export const UPDATE_APN_ERROR = "UPDATE_APN_ERROR";

export const RESUME_LINE = "RESUME_LINE";
export const RESUME_LINE_ERROR = "RESUME_LINE_ERROR";

export const BARRING_ACTION = "BARRING_ACTION";
export const BARRING_ACTION_ERROR = "BARRING_ACTION_ERROR";

export const UNBARRING_ACTION = "UNBARRING_ACTION";
export const UNBARRING_ACTION_ERROR = "UNBARRING_ACTION_ERROR";

export const SUSPEND_LINE = "SUSPEND_LINE";
export const SUSPEND_LINE_ERROR = "SUSPEND_LINE_ERROR";

export const IMEI = "IMEI";
export const IMEI_ERROR = "IMEI_ERROR";
export const IMEI_CLEAN = "IMEI_CLEAN";
export const IMEI_CLEAN_ERROR = "IMEI_CLEAN_ERROR";

export const EVENTS = "EVENT";
export const EVENTS_ERROR = "EVENT_ERROR";

export const LOCK_IMEI = "LOCK_IMEI";
export const LOCK_IMEI_ERROR = "LOCK_IMEI_ERROR";

export const CLEAN_LOCK_IMEI = "CLEAN_LOCK_IMEI";
export const CLEAN_LOCK_IMEI_ERROR = "CLEAN_LOCK_IMEI_ERROR";

export const CLEAN_UNLOCK_IMEI = "CLEAN_UNLOCK_IMEI";
export const CLEAN_UNLOCK_IMEI_ERROR = "CLEAN_UNLOCK_IMEI_ERROR";

export const UNLOCK_IMEI = "UNLOCK_IMEI";
export const UNLOCK_IMEI_ERROR = "UNLOCK_IMEI_ERROR";

export const SUPPLEMENTARY_SERVICE = "SUPPLEMENTARY_SERVICE";
export const SUPPLEMENTARY_SERVICE_ERROR = "SUPPLEMENTARY_SERVICE_ERROR";
export const SUPPLEMENTARY_SERVICE_CLEAN = "SUPPLEMENTARY_SERVICE_CLEAN";
export const SUPPLEMENTARY_SERVICE_CLEAN_ERROR =
    "SUPPLEMENTARY_SERVICE_CLEAN_ERROR";

export const CHANGE_SUPPLEMENTARY_SERVICE = "CHANGE_SUPPLEMENTARY_SERVICE";
export const CHANGE_SUPPLEMENTARY_SERVICE_ERROR =
    "CHANGE_SUPPLEMENTARY_SERVICE_ERROR";

export const NIR = "NIR";
export const NIR_ERROR = "NIR_ERROR";
export const NIRS_CLEAN = "NIRS_CLEAN";
export const NIRS_CLEAN_ERROR = "NIRS_CLEAN_ERROR";

export const CHANGE_NIR_CLEAN = "CHANGE_NIR_CLEAN";
export const CHANGE_NIR_CLEAN_ERROR = "CHANGE_NIR_CLEAN_ERROR";

export const CHANGE_NIR = "CHANGE_NIR";
export const CHANGE_NIR_ERROR = "CHANGE_NIR_ERROR";

export const CHANGE_SIM = "CHANGE_SIM";
export const CHANGE_SIM_ERROR = "CHANGE_SIM_ERROR";
export const CHANGE_SIM_CLEAN = "CHANGE_SIM_CLEAN";
export const CHANGE_SIM_CLEAN_ERROR = "CHANGE_SIM_CLEAN_ERROR";

export const STATUS_LIST = "STATUS_LIST";
export const STATUS_LIST_ERROR = "STATUS_LIST_ERROR";

export const CHANGE_STATUS_TICKET = "CHANGE_STATUS_TICKET";
export const CHANGE_STATUS_TICKET_ERROR = "CHANGE_STATUS_TICKET_ERROR";

export const USERS = "USERS";
export const USERS_ERROR = "USERS_ERROR";

export const CHANGE_USER = "CHANGE_USER";
export const CHANGE_USER_ERROR = "CHANGE_USER_ERROR";

export const TICKET_BY_ID = "TICKET_BY_ID";
export const TICKET_BY_ID_ERROR = "TICKET_BY_ID_ERROR";
export const CLEAN_TICKET_BY_ID = "CLEAN_TICKET_BY_ID";
export const CLEAN_TICKET_BY_ID_ERROR = "CLEAN_TICKET_BY_ID_ERROR";

export const CLEAN_BARRING_ACTION = "CLEAN_BARRING_ACTION";
export const CLEAN_BARRING_ACTION_ERROR = "CLEAN_BARRING_ACTION_ERROR";

export const PORTABILITY_ACTION = "PORTABILITY_ACTION";
export const PORTABILITY_ACTION_ERROR = "PORTABILITY_ERROR";
export const CLEAN_PORTABILITY_ACTION = "CLEAN_PORTABILITY_ACTION";
export const CLEAN_PORTABILITY_ACTION_ERROR = "CLEAN_PORTABILITY_ACTION_ERROR";

export const LEADS = "LEADS";
export const LEADS_ERROR = "LEADS_ERROR";
export const CLEAN_LEADS = "CLEAN_LEADS";
export const CLEAN_LEADS_ERROR = "CLEAN_LEADS_ERROR";

export const LINKS = "LINKS";
export const LINKS_ERROR = "LINKS_ERROR";
export const CLEAN_LINKS = "CLEAN_LINKS";
export const CLEAN_LINKS_ERROR = "CLEAN_LINKS_ERROR";

export const LEAD = "LEAD";
export const LEAD_ERROR = "LEAD_ERROR";
export const CLEAN_LEAD = "CLEAN_LEAD";
export const CLEAN_LEAD_ERROR = "CLEAN_LEAD_ERROR";

export const NEIGHBORHOOD = "NEIGHBORHOOD";
export const NEIGHBORHOOD_ERROR = "NEIGHBORHOOD_ERROR";
export const CLEAN_NEIGHBORHOOD = "CLEAN_NEIGHBORHOOD";
export const CLEAN_NEIGHBORHOOD_ERROR = "CLEAN_NEIGHBORHOOD_ERROR";

export const MUNICIPALITY = "MUNICIPALITY";
export const MUNICIPALITY_ERROR = "MUNICIPALITY_ERROR";
export const CLEAN_MUNICIPALITY = "CLEAN_MUNICIPALITY";
export const CLEAN_MUNICIPALITY_ERROR = "CLEAN_MUNICIPALITY_ERROR";

export const OFFERS = "OFFERS";
export const OFFERS_ERROR = "OFFERS_ERROR";
export const CLEAN_OFFERS = "CLEAN_OFFERS";
export const CLEAN_OFFERS_ERROR = "CLEAN_OFFERS_ERROR";

export const CHECK_IMEI = "CHECK_IMEI";
export const CHECK_IMEI_ERROR = "CHECK_IMEI_ERROR";
export const CLEAN_CHECK_IMEI = "CLEAN_CHECK_IMEI";
export const CLEAN_CHECK_IMEI_ERROR = "CLEAN_CHECK_IMEI_ERROR";

export const BACKOFFICE_LEAD_STATUS = "BACKOFFICE_LEAD_STATUS";
export const BACKOFFICE_LEAD_STATUS_ERROR = "BACKOFFICE_LEAD_STATUS_ERROR";
export const CLEAN_BACKOFFICE_LEAD_STATUS = "CLEAN_BACKOFFICE_LEAD_STATUS";
export const CLEAN_BACKOFFICE_LEAD_STATUS_ERROR =
    "CLEAN_BACKOFFICE_LEAD_STATUS_ERROR";

export const BACKOFFICE_STATUS = "BACKOFFICE_STATUS";
export const BACKOFFICE_STATUS_ERROR = "BACKOFFICE_STATUS_ERROR";
export const CLEAN_BACKOFFICE_STATUS = "CLEAN_BACKOFFICE_STATUS";
export const CLEAN_BACKOFFICE_STATUS_ERROR = "CLEAN_BACKOFFICE_STATUS_ERROR";

export const PUT_LEAD = "PUT_LEAD";
export const PUT_LEAD_ERROR = "PUT_LEAD_ERROR";
export const CLEAN_PUT_LEAD = "CLEAN_PUT_LEAD";
export const CLEAN_PUT_LEAD_ERROR = "CLEAN_PUT_LEAD_ERROR";

export const CHECK_PAYMENT_STATUS = "CHECK_PAYMENT_STATUS";
export const CHECK_PAYMENT_STATUS_ERROR = "CHECK_PAYMENT_STATUS_ERROR";
export const CLEAN_CHECK_PAYMENT_STATUS = "CLEAN_CHECK_PAYMENT_STATUS";
export const CLEAN_CHECK_PAYMENT_STATUS_ERROR =
    "CLEAN_CHECK_PAYMENT_STATUS_ERROR";

export const LEAD_ASSIGNMENT = "LEAD_ASSIGNMENT";
export const LEAD_ASSIGNMENT_ERROR = "LEAD_ASSIGNMENT_ERROR";
export const CLEAN_LEAD_ASSIGNMENT = "CLEAN_LEAD_ASSIGNMENT";
export const CLEAN_LEAD_ASSIGNMENT_ERROR = "CLEAN_LEAD_ASSIGNMENT_ERROR";

export const LEAD_BY_CAMPAING = "LEAD_BY_CAMPAING";
export const LEAD_BY_CAMPAING_ERROR = "LEAD_BY_CAMPAING_ERROR";
export const CLEAN_LEAD_BY_CAMPAING = "CLEAN_LEAD_BY_CAMPAING";
export const CLEAN_LEAD_BY_CAMPAING_ERROR = "CLEAN_LEAD_BY_CAMPAING_ERROR";

export const ORDERS_BY_PRODUCT = "ORDERS_BY_PRODUCT";
export const ORDERS_BY_PRODUCT_ERROR = "ORDERS_BY_PRODUCT_ERROR";
export const CLEAN_ORDERS_BY_PRODUCT = "CLEAN_ORDERS_BY_PRODUCT";
export const CLEAN_ORDERS_BY_PRODUCT_ERROR = "CLEAN_ORDERS_BY_PRODUCT_ERROR";

export const GET_CAMPAING = "GET_CAMPAING";
export const GET_CAMPAING_ERROR = "GET_CAMPAING_ERROR";
export const CLEAN_GET_CAMPAING = "CLEAN_GET_CAMPAING";
export const CLEAN_GET_CAMPAING_ERROR = "CLEAN_GET_CAMPAING_ERROR";

export const GET_CHANNEL = "GET_CHANNEL";
export const GET_CHANNEL_ERROR = "GET_CHANNEL_ERROR";
export const CLEAN_GET_CHANNEL = "CLEAN_GET_CHANNEL";
export const CLEAN_GET_CHANNEL_ERROR = "CLEAN_GET_CHANNEL_ERROR";

export const GET_CAMPAIGN_CHANNEL = "GET_CAMPAIGN_CHANNEL";
export const GET_CAMPAIGN_CHANNEL_ERROR = "GET_CAMPAIGN_CHANNEL_ERROR";
export const CLEAN_GET_CAMPAIGN_CHANNEL = "CLEAN_GET_CAMPAIGN_CHANNEL";
export const CLEAN_GET_CAMPAIGN_CHANNEL_ERROR =
    "CLEAN_GET_CAMPAIGN_CHANNEL_ERROR";

export const CREATE_LEAD_BY_CHANNEL = "CREATE_LEAD_BY_CHANNEL ";
export const CREATE_LEAD_BY_CHANNEL_ERROR = "CREATE_LEAD_BY_CHANNEL_ERROR";

export const CREATE_LEAD_BY_CHANNEL_CLEAN = "CREATE_LEAD_BY_CHANNEL_CLEAN";
export const CLEAN_CREATE_LEAD_BY_CHANNEL_ERROR =
    "CLEAN_CREATE_LEAD_BY_CHANNEL_ERROR";

export const CREATE_LEAD = "CREATE_LEAD";
export const CREATE_LEAD_ERROR = "CREATE_LEAD_ERROR";

export const CREATE_LEAD_CLEAN = "CREATE_LEAD_CLEAN";
export const CLEAN_CREATE_LEAD_ERROR = "CLEAN_CREATE_LEAD_ERROR";

export const PATCH_LEAD = "PATCH_LEAD";
export const PATCH_LEAD_ERROR = "PATCH_LEAD_ERROR";

export const PATCH_LEAD_ERROR_CLEAN = "PATCH_LEAD_ERROR_CLEAN";
export const CLEAN_PATCH_LEAD_ERROR = "CLEAN_PATCH_LEAD_ERROR";

export const GET_LANDING_LIST = "GET_LANDING_LIST";
export const GET_LANDING_LIST_ERROR = "GET_LANDING_LIST_ERROR";

export const GET_LEAD_STATUS_LIST = "GET_LEAD_STATUS_LIST";
export const GET_LEAD_STATUS_LIST_ERROR = "GET_LEAD_STATUS_LIST_ERROR";

export const ACTIVATIONS_AND_DEACTIVATIONS = "ACTIVATIONS_AND_DEACTIVATIONS";
export const ACTIVATIONS_AND_DEACTIVATIONS_ERROR =
    "ACTIVATIONS_AND_DEACTIVATIONS_ERROR";
export const CLEAN_ACTIVATIONS_AND_DEACTIVATIONS =
    "CLEAN_ACTIVATIONS_AND_DEACTIVATIONS";
export const CLEAN_ACTIVATIONS_AND_DEACTIVATIONS_ERROR =
    "CLEAN_ACTIVATIONS_AND_DEACTIVATIONS_ERROR";

export const PREPAID_BY_DAY = "PREPAID_BY_DAY";
export const PREPAID_BY_DAY_ERROR = "PREPAID_BY_DAY_ERROR";
export const CLEAN_PREPAID_BY_DAY = "CLEAN_PREPAID_BY_DAY";
export const CLEAN_PREPAID_BY_DAY_ERROR = "CLEAN_PREPAID_BY_DAY_ERROR";

export const PREPAID_BY_PRODUCT = "PREPAID_BY_PRODUCT";
export const PREPAID_BY_PRODUCT_ERROR = "PREPAID_BY_PRODUCT_ERROR";
export const CLEAN_PREPAID_BY_PRODUCT = "CLEAN_PREPAID_BY_PRODUCT";
export const CLEAN_PREPAID_BY_PRODUCT_ERROR = "CLEAN_PREPAID_BY_PRODUCT_ERROR";

export const SALE_ORDERS = "SALE_ORDERS";
export const SALE_ORDERS_ERROR = "SALE_ORDERS_ERROR";
export const CLEAN_SALE_ORDERS = "CLEAN_SALE_ORDERS";
export const CLEAN_SALE_ORDERS_ERROR = "CLEAN_SALE_ORDERS_ERROR";

export const CREATE_INCIDENCE = "CREATE_INCIDENCE";
export const CREATE_INCIDENCE_ERROR = "CREATE_INCIDENCE_ERROR";
export const CLEAN_CREATE_INCIDENCE = "CLEAN_CREATE_INCIDENCE";
export const CLEAN_CREATE_INCIDENCE_ERROR = "CLEAN_CREATE_INCIDENCE_ERROR";
export const SALE_ORDER_DETAIL = "SALE_ORDER_DETAIL";
export const SALE_ORDER_DETAIL_ERROR = "SALE_ORDER_DETAIL_ERROR";
export const CLEAN_SALE_ORDER_DETAIL = "CLEAN_SALE_ORDER_DETAIL";
export const CLEAN_SALE_ORDER_DETAIL_ERROR = "CLEAN_SALE_ORDER_DETAIL_ERROR";

export const GENERAL_TICKETS = "GENERAL_TICKETS";
export const GENERAL_TICKETS_ERROR = "GENERAL_TICKETS_ERROR";
export const CLEAN_GENERAL_TICKETS = "CLEAN_GENERAL_TICKETS";
export const CLEAN_GENERAL_TICKETS_ERROR = "CLEAN_GENERAL_TICKETS_ERROR";
export const SALE_ORDER_ESIMQR = "SALE_ORDER_ESIMQR";
export const SALE_ORDER_ESIMQR_ERROR = "SALE_ORDER_ESIMQR_ERROR";
export const CLEAN_SALE_ORDER_ESIMQR = "CLEAN_SALE_ORDER_ESIMQR,";
export const CLEAN_SALE_ORDER_ESIMQR_ERROR = "CLEAN_SALE_ORDER_ESIMQR_ERROR,";

export const MY_SALES = "MY_SALES";
export const MY_SALES_ERROR = "MY_SALES_ERROR";
export const CLEAN_MY_SALES = "CLEAN_MY_SALES,";
export const CLEAN_MY_SALES_ERROR = "CLEAN_MY_SALES_ERROR,";

export const MY_SALES_DETAILS = "MY_SALES_DETAILS";
export const MY_SALES_DETAILS_ERROR = "MY_SALES_DETAILS_ERROR";
export const CLEAN_MY_SALES_DETAILS = "CLEAN_MY_SALES_DETAILS";
export const CLEAN_MY_SALES_DETAILS_ERROR = "CLEAN_MY_SALES_DETAILS_ERROR";

export const POST_BIND_ASSISTED_ORDER = "POST_BIND_ASSISTED_ORDER";
export const POST_BIND_ASSISTED_ORDER_ERROR = "POST_BIND_ASSISTED_ORDER_ERROR";
export const CLEAN_POST_BIND_ASSISTED_ORDER = "CLEAN_POST_BIND_ASSISTED_ORDER";
export const CLEAN_POST_BIND_ASSISTED_ORDER_ERROR =
    "CLEAN_POST_BIND_ASSISTED_ORDER_ERROR";

export const MY_SALES_EXECUTIVE = "MY_SALES_EXECUTIVE";
export const MY_SALES_EXECUTIVE_ERROR = "MY_SALES_EXECUTIVE_ERROR";
export const CLEAN_MY_SALES_EXECUTIVE = "CLEAN_MY_SALES_EXECUTIVE,";
export const CLEAN_MY_SALES_EXECUTIVE_ERROR = "CLEAN_MY_SALES_EXECUTIVE_ERROR,";

export const MY_SALES_DETAILS_EXECUTIVE = "MY_SALES_DETAILS_EXECUTIVE";
export const MY_SALES_DETAILS_EXECUTIVE_ERROR =
    "MY_SALES_DETAILS_EXECUTIVE_ERROR";
export const CLEAN_MY_SALES_DETAILS_EXECUTIVE =
    "CLEAN_MY_SALES_DETAILS_EXECUTIVE,";
export const CLEAN_MY_SALES_DETAILS_EXECUTIVE_ERROR =
    "CLEAN_MY_SALES_DETAILS_EXECUTIVE_ERROR,";

export const DAILY_REFILLS_PER_PRODUCT = "DAILY_REFILLS_PER_PRODUCT";
export const DAILY_REFILLS_PER_PRODUCT_ERROR =
    "DAILY_REFILLS_PER_PRODUCT_ERROR";
export const CLEAN_DAILY_REFILLS_PER_PRODUCT =
    "CLEAN_DAILY_REFILLS_PER_PRODUCT,";
export const CLEAN_DAILY_REFILLS_PER_PRODUCT_ERROR =
    "CLEAN_DAILY_REFILLS_PER_PRODUCT_ERROR,";

export const TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR =
    "TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR";
export const TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR =
    "TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR";
export const CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR =
    "CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR,";
export const CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR =
    "CLEAN_TOTAL_PREPAIDS_BY_SUB_DISTRIBUTOR_ERROR,";
export const CONSULTINGCHECKIMEI = "CONSULTINGCHECKIMEI";
export const CONSULTINGCHECKIMEI_ERROR = "CONSULTINGCHECKIMEI_ERROR";
export const CLEAN_CONSULTINGCHECKIMEI = "CLEAN_CONSULTINGCHECKIMEI,";
export const CLEAN_CONSULTINGCHECKIMEI_ERROR =
    "CLEAN_CONSULTINGCHECKIMEI_ERROR,";

export const GET_SALES_MONTHLY = "GET_SALES_MONTHLY";
export const GET_SALES_MONTHLY_ERROR = "GET_SALES_MONTHLY_ERROR";
export const CLEAN_GET_SALES_MONTHLY = "CLEAN_GET_SALES_MONTHLY";
export const CLEAN_GET_SALES_MONTHLY_ERROR = "CLEAN_GET_SALES_MONTHLY_ERROR";

export const GET_SALES_WEEKLY = "GET_SALES_WEEKLY";
export const GET_SALES_WEEKLY_ERROR = "GET_SALES_WEEKLY_ERROR";
export const CLEAN_GET_SALES_WEEKLY = "CLEAN_GET_SALES_WEEKLY";
export const CLEAN_GET_SALES_WEEKLY_ERROR = "CLEAN_GET_SALES_WEEKLY_ERROR";

export const GET_SALES_DAILY = "GET_SALES_DAILY";
export const GET_SALES_DAILY_ERROR = "GET_SALES_DAILY_ERROR";
export const CLEAN_GET_SALES_DAILY = "CLEAN_GET_SALES_DAILY";
export const CLEAN_GET_SALES_DAILY_ERROR = "CLEAN_GET_SALES_DAILY_ERROR";

export const GET_EMAIL_REPORT = "GET_EMAIL_REPORT";
export const GET_EMAIL_REPORT_ERROR = "GET_EMAIL_REPORT_ERROR";
export const CLEAN_GET_EMAIL_REPORT = "CLEAN_GET_EMAIL_REPORT";
export const CLEAN_GET_EMAIL_REPORT_ERROR = "CLEAN_GET_EMAIL_REPORT_ERROR";

export const GET_CONTACT_PHONE_REPORT = "GET_CONTACT_PHONE_REPORT";
export const GET_CONTACT_PHONE_REPORT_ERROR = "GET_CONTACT_PHONE_REPORT_ERROR";
export const CLEAN_GET_CONTACT_PHONE_REPORT = "CLEAN_GET_CONTACT_PHONE_REPORT";
export const CLEAN_GET_CONTACT_PHONE_REPORT_ERROR =
    "CLEAN_GET_CONTACT_PHONE_REPORT_ERROR";

export const GET_IP_RESTRICTION_REPORT = "GET_IP_RESTRICTION_REPORT";
export const GET_IP_RESTRICTION_REPORT_ERROR =
    "GET_IP_RESTRICTION_REPORT_ERROR";
export const CLEAN_GET_IP_RESTRICTION_REPORT =
    "CLEAN_GET_IP_RESTRICTION_REPORT";
export const CLEAN_GET_IP_RESTRICTION_REPORT_ERROR =
    "CLEAN_GET_IP_RESTRICTION_REPORT_ERROR";

export const CHANGE_ESIM_TO_SIM = "CHANGE_ESIM_TO_SIM";
export const CHANGE_ESIM_TO_SIM_ERROR = "CHANGE_ESIM_TO_SIM_ERROR";
export const CLEAN_CHANGE_ESIM_TO_SIM = "CLEAN_CHANGE_ESIM_TO_SIM";
export const CLEAN_CHANGE_ESIM_TO_SIM_ERROR = "CLEAN_CHANGE_ESIM_TO_SIM_ERROR";

export const CHANGE_SIM_TO_ESIM = "CHANGE_SIM_TO_ESIM";
export const CHANGE_SIM_TO_ESIM_ERROR = "CHANGE_SIM_TO_ESIM_ERROR";
export const CLEAN_CHANGE_SIM_TO_ESIM = "CLEAN_CHANGE_SIM_TO_ESIM";
export const CLEAN_CHANGE_SIM_TO_ESIM_ERROR = "CLEAN_CHANGE_SIM_TO_ESIM_ERROR";

export const GET_SUPPORT_GIFT_ORDERS = "GET_SUPPORT_GIFT_ORDERS";
export const GET_SUPPORT_GIFT_ORDERS_ERROR = "GET_SUPPORT_GIFT_ORDERS_ERROR";
export const GET_SUPPORT_GIFT_ORDERS_CLEAN = "GET_SUPPORT_GIFT_ORDERS_CLEAN";
export const GET_SUPPORT_GIFT_ORDERS_CLEAN_ERROR =
    "GET_SUPPORT_GIFT_ORDERS_CLEAN_ERROR";

export const GET_SUPPORT_GIFT_ORDERS_DETAIL = "GET_SUPPORT_GIFT_ORDERS_DETAIL";
export const GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR =
    "GET_SUPPORT_GIFT_ORDERS_DETAIL_ERROR";
export const GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN =
    "GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN";
export const GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR =
    "GET_SUPPORT_GIFT_ORDERS_DETAIL_CLEAN_ERROR";

export const TRANSFER_SALES = "TRANSFER_SALES";
export const TRANSFER_SALES_ERROR = "TRANSFER_SALES_ERROR";
export const CLEAN_TRANSFER_SALES = "CLEAN_TRANSFER_SALES";
export const CLEAN_TRANSFER_SALES_ERROR = "CLEAN_TRANSFER_SALES_ERROR";

export const OFFER_SIM_PURCHASE = "OFFER_SIM_PURCHASE";
export const OFFER_SIM_PURCHASE_ERROR = "OFFER_SIM_PURCHASE_ERROR";
export const CLEAN_OFFER_SIM_PURCHASE = "CLEAN_OFFER_SIM_PURCHASE";
export const CLEAN_OFFER_SIM_PURCHASE_ERROR = "CLEAN_OFFER_SIM_PURCHASE_ERROR";

export const LIST_TRANSFER_SALES = "LIST_TRANSFER_SALES";
export const LIST_TRANSFER_SALES_ERROR = "LIST_TRANSFER_SALES_ERROR";
export const CLEAN_LIST_TRANSFER_SALES = "CLEAN_LIST_TRANSFER_SALES";
export const CLEAN_LIST_TRANSFER_SALES_ERROR =
    "CLEAN_LIST_TRANSFER_SALES_ERROR";

export const DETAIL_TRANSFER_SALES = "DETAIL_TRANSFER_SALES";
export const DETAIL_TRANSFER_SALES_ERROR = "DETAIL_TRANSFER_SALES_ERROR";
export const CLEAN_DETAIL_TRANSFER_SALES = "CLEAN_DETAIL_TRANSFER_SALES";
export const CLEAN_DETAIL_TRANSFER_SALES_ERROR =
    "CLEAN_DETAIL_TRANSFER_SALES_ERROR";

export const PROCESS_TRANSFER_SALES = "PROCESS_TRANSFER_SALES";
export const PROCESS_TRANSFER_SALES_ERROR = "PROCESS_TRANSFER_SALES_ERROR";
export const CLEAN_PROCESS_TRANSFER_SALES = "CLEAN_PROCESS_TRANSFER_SALES";
export const CLEAN_PROCESS_TRANSFER_SALES_ERROR =
    "CLEAN_PROCESS_TRANSFER_SALES_ERROR";

export const NETPAY = "NETPAY";
export const NETPAY_ERROR = "NETPAY_ERROR";
export const CLEAN_NETPAY = "CLEAN_NETPAY";
export const CLEAN_NETPAY_ERROR = "CLEAN_NETPAY_ERROR";

export const SIM_ACTIVATION = "SIM_ACTIVATION";
export const SIM_ACTIVATION_ERROR = "SIM_ACTIVATION_ERROR";
export const CLEAN_SIM_ACTIVATION = "CLEAN_SIM_ACTIVATION";
export const CLEAN_SIM_ACTIVATION_ERROR = "CLEAN_SIM_ACTIVATION_ERROR";

export const DETAIL_TRANSACTIONS_NETPAY = "DETAIL_TRANSACTIONS_NETPAY";
export const DETAIL_TRANSACTIONS_ERROR = "DETAIL_TRANSACTIONS_ERROR";
export const CLEAN_DETAIL_TRANSACTIONS_NETPAY =
    "CLEAN_DETAIL_TRANSACTIONS_NETPAY";
export const CLEAN_DETAIL_TRANSACTIONS_NETPAY_ERROR =
    "CLEAN_DETAIL_TRANSACTIONS_NETPAY_ERROR";

export const RECHARGE_HISTORY = "RECHARGE_HISTORY";
export const RECHARGE_HISTORY_ERROR = "RECHARGE_HISTORY_ERROR";
export const CLEAN_RECHARGE_HISTORY = "CLEAN_RECHARGE_HISTORY";
export const CLEAN_RECHARGE_HISTORY_ERROR = "CLEAN_RECHARGE_HISTORY_ERROR";

export const LIST_TRANSFER_STP = "LIST_TRANSFER_STP";
export const LIST_TRANSFER_STP_ERROR = "LIST_TRANSFER_STP_ERROR";
export const CLEAN_LIST_TRANSFER_STP = "CLEAN_LIST_TRANSFER_STP";
export const CLEAN_LIST_TRANSFER_STP_ERROR = "CLEAN_LIST_TRANSFER_STP_ERROR";

export const DETAIL_TRANSACTIONS_STP = "DETAIL_TRANSACTIONS_STP";
export const DETAIL_TRANSACTIONS_STP_ERROR = "DETAIL_TRANSACTIONS_STP_ERROR";
export const CLEAN_DETAIL_TRANSACTIONS_STP = "CLEAN_DETAIL_TRANSACTIONS_STP";
export const CLEAN_DETAIL_TRANSACTIONS_STP_ERROR =
    "CLEAN_DETAIL_TRANSACTIONS_STP_ERROR";

export const ATN_PORTABILITY = "ATN_PORTABILITY";
export const ATN_PORTABILITY_ERROR = "ATN_PORTABILITY_ERROR";
export const CLEAN_ATN_PORTABILITY = "CLEAN_ATN_PORTABILITY";
export const CLEAN_ATN_PORTABILITY_ERROR = "CLEAN_ATN_PORTABILITY_ERROR";

export const MAKE_PREPAID = "MAKE_PREPAID";
export const MAKE_PREPAID_ERROR = "MAKE_PREPAID_ERROR";
export const CLEAN_MAKE_PREPAID = "CLEAN_MAKE_PREPAID";
export const CLEAN_MAKE_PREPAID_ERROR = "CLEAN_MAKE_PREPAID_ERROR";

export const PRODUCTS_PREPAID = "PRODUCTS_PREPAID";
export const PRODUCTS_PREPAID_ERROR = "PRODUCTS_PREPAID_ERROR";
export const CLEAN_PRODUCTS_PREPAID = "CLEAN_PRODUCTS_PREPAID";
export const CLEAN_PRODUCTS_PREPAID_ERROR = "CLEAN_PRODUCTS_PREPAID_ERROR";

export const TRANSACTIONS_TOKU = "TRANSACTIONS_TOKU";
export const TRANSACTIONS_TOKU_ERROR = "TRANSACTIONS_TOKU_ERROR";
export const CLEAN_TRANSACTIONS_TOKU = "CLEAN_TRANSACTIONS_TOKU";
export const CLEAN_TRANSACTIONS_TOKU_ERROR = "CLEAN_TRANSACTIONS_TOKU_ERROR";

export const DETAILS_TRANSACTIONS_TOKU = "DETAILS_TRANSACTIONS_TOKU";
export const DETAILS_TRANSACTIONS_TOKU_ERROR =
    "DETAILS_TRANSACTIONS_TOKU_ERROR";
export const CLEAN_DETAILS_TRANSACTIONS_TOKU =
    "CLEAN_DETAILS_TRANSACTIONS_TOKU";
export const CLEAN_DETAILS_TRANSACTIONS_TOKU_ERROR =
    "CLEAN_DETAILS_TRANSACTIONS_TOKU_ERROR";

export const DETAILS_TRANSACTIONS_FRAUD = "DETAILS_TRANSACTIONS_FRAUD";
export const DETAILS_TRANSACTIONS_FRAUD_ERROR =
    "DETAILS_TRANSACTIONS_FRAUD_ERROR";
export const CLEAN_DETAILS_TRANSACTIONS_FRAUD =
    "CLEAN_DETAILS_TRANSACTIONS_FRAUD";
export const CLEAN_DETAILS_TRANSACTIONS_FRAUD_ERROR =
    "CLEAN_DETAILS_TRANSACTIONS_FRAUD_ERROR";

export const DETAILS_TRANSACTIONS_CHARGEBACK =
    "DETAILS_TRANSACTIONS_CHARGEBACK";
export const DETAILS_TRANSACTIONS_CHARGEBACK_ERROR =
    "DETAILS_TRANSACTIONS_CHARGEBACK_ERROR";
export const CLEAN_DETAILS_TRANSACTIONS_CHARGEBACK =
    "CLEAN_DETAILS_TRANSACTIONS_CHARGEBACK";
export const CLEAN_DETAILS_TRANSACTIONS_CHARGEBACK_ERROR =
    "CLEAN_DETAILS_TRANSACTIONS_CHARGEBACK_ERROR";

export const REPORT_TRANSACTIONS_FRAUD_SUCCESS =
    "REPORT_TRANSACTIONS_FRAUD_SUCCESS";
export const REPORT_TRANSACTIONS_FRAUD_FAILURE =
    "REPORT_TRANSACTIONS_FRAUD_FAILURE";
export const CLEAN_REPORT_TRANSACTIONS_FRAUD_SUCCESS =
    "CLEAN_REPORT_TRANSACTIONS_FRAUD_SUCCESS";
export const CLEAN_REPORT_TRANSACTIONS_FRAUD_FAILURE =
    "CLEAN_REPORT_TRANSACTIONS_FRAUD_FAILURE";

export const REPORT_TRANSACTIONS_CHARGEBACK_SUCCESS =
    "REPORT_TRANSACTIONS_CHARGEBACK_SUCCESS";
export const REPORT_TRANSACTIONS_CHARGEBACK_FAILURE =
    "REPORT_TRANSACTIONS_CHARGEBACK_FAILURE";
export const CLEAN_DETAILS_PREPAID_CHARGEBACK =
    "CLEAN_DETAILS_PREPAID_CHARGEBACK";
export const CLEAN_DETAILS_PREPAID_CHARGEBACK_ERROR =
    "CLEAN_DETAILS_PREPAID_CHARGEBACK_ERROR";

export const LIST_SIM_IPB = "LIST_SIM_IPB";
export const LIST_SIM_IPB_ERROR = "LIST_SIM_IPB_ERROR";
export const CLEAN_LIST_SIM_IPB = "CLEAN_LIST_SIM_IPB";
export const CLEAN_LIST_SIM_IPB_ERROR = "CLEAN_LIST_SIM_IPB_ERROR";

export const LIST_GIFT_SIM_IPB = "LIST_GIFT_SIM_IPB";
export const LIST_GIFT_SIM_IPB_ERROR = "LIST_GIFT_SIM_IPB_ERROR";
export const CLEAN_LIST_GIFT_SIM_IPB = "CLEAN_LIST_GIFT_SIM_IPB";
export const CLEAN_LIST_GIFT_SIM_IPB_ERROR = "CLEAN_LIST_GIFT_SIM_IPB_ERROR";

export const PREPAID = "PREPAID";
export const PREPAID_ERROR = "PREPAID_ERROR";
export const CLEAN_PREPAID = "CLEAN_PREPAID";
export const CLEAN_PREPAID_ERROR = "CLEAN_PREPAID_ERROR";

export const DETAIL_LIST_SIM_IPB = "DETAIL_LIST_SIM_IPB";
export const DETAIL_LIST_SIM_IPB_ERROR = "DETAIL_LIST_SIM_IPB_ERROR";
export const CLEAN_DETAIL_LIST_SIM_IPB = "CLEAN_DETAIL_LIST_SIM_IPB";
export const CLEAN_DETAIL_LIST_SIM_IPB_ERROR =
    "CLEAN_DETAIL_LIST_SIM_IPB_ERROR";

export const DETAIL_LIST_GIFT_SIM_IPB = "DETAIL_LIST_GIFT_SIM_IPB";
export const DETAIL_LIST_GIFT_SIM_IPB_ERROR = "DETAIL_LIST_GIFT_SIM_IPB_ERROR";
export const CLEAN_DETAIL_LIST_GIFT_SIM_IPB = "CLEAN_DETAIL_LIST_GIFT_SIM_IPB";
export const CLEAN_DETAIL_LIST_GIFT_SIM_IPB_ERROR =
    "CLEAN_DETAIL_LIST_GIFT_SIM_IPB_ERROR";

export const IPB_INVOICE_LIST = "IPB_INVOICE_LIST";
export const IPB_INVOICE_LIST_ERROR = "IPB_INVOICE_LIST_ERROR";
export const CLEAN_IPB_INVOICE_LIST = "CLEAN_IPB_INVOICE_LIST";
export const CLEAN_IPB_INVOICE_LIST_ERROR = "CLEAN_IPB_INVOICE_LIST_ERROR";

export const GENERATE_INVOICES = "GENERATE_INVOICES";
export const GENERATE_INVOICES_ERROR = "GENERATE_INVOICES_ERROR";
export const CLEAN_GENERATE_INVOICES = "CLEAN_GENERATE_INVOICES";
export const CLEAN_GENERATE_INVOICES_ERROR = "CLEAN_GENERATE_INVOICES_ERROR";

export const EDIT_LEGAL_DATA = "EDIT_LEGAL_DATA";
export const EDIT_LEGAL_DATA_ERROR = "EDIT_LEGAL_DATA_ERROR";
export const CLEAN_EDIT_LEGAL_DATA = "CLEAN_EDIT_LEGAL_DATA";
export const CLEAN_EDIT_LEGAL_DATA_ERROR = "CLEAN_EDIT_LEGAL_DATA_ERROR";

export const OPENPAY = "OPENPAY";
export const OPENPAY_ERROR = "OPENPAY_ERROR";
export const CLEAN_OPENPAY = "CLEAN_OPENPAY";
export const CLEAN_OPENPAY_ERROR = "CLEAN_OPENPAY_ERROR";

export const DETAIL_TRANSACTIONS_OPENPAY = "DETAIL_TRANSACTIONS_OPENPAY";
export const DETAIL_TRANSACTIONS_OPENPAY_ERROR =
    "DETAIL_TRANSACTIONS_OPENPAY_ERROR";
export const CLEAN_DETAIL_TRANSACTIONS_OPENPAY =
    "CLEAN_DETAIL_TRANSACTIONS_OPENPAY";
export const CLEAN_DETAIL_TRANSACTIONS_OPENPAY_ERROR =
    "CLEAN_DETAIL_TRANSACTIONS_OPENPAY_ERROR";

export const IPB_DIRECTORY_LISTING = "IPB_DIRECTORY_LISTING";
export const IPB_DIRECTORY_LISTING_ERROR = "IPB_DIRECTORY_LISTING_ERROR";
export const CLEAN_IPB_DIRECTORY_LISTING = "CLEAN_IPB_DIRECTORY_LISTING";
export const CLEAN_IPB_DIRECTORY_LISTING_ERROR =
    "CLEAN_IPB_DIRECTORY_LISTING_ERROR";

export const IPB_DIRECTORY_DETAIL = "IPB_DIRECTORY_DETAIL";
export const IPB_DIRECTORY_DETAIL_ERROR = "IPB_DIRECTORY_DETAIL_ERROR";
export const CLEAN_IPB_DIRECTORY_DETAIL = "CLEAN_IPB_DIRECTORY_DETAIL";
export const CLEAN_IPB_DIRECTORY_DETAIL_ERROR =
    "CLEAN_IPB_DIRECTORY_DETAIL_ERROR";

export const IPB_DIRECTORY_MODERATION_LISTING =
    "IPB_DIRECTORY_MODERATION_LISTING";
export const IPB_DIRECTORY_MODERATION_LISTING_ERROR =
    "IPB_DIRECTORY_MODERATION_LISTING_ERROR";
export const CLEAN_IPB_DIRECTORY_MODERATION_LISTING =
    "CLEAN_IPB_DIRECTORY_MODERATION_LISTING";
export const CLEAN_IPB_DIRECTORY_MODERATION_LISTING_ERROR =
    "CLEAN_IPB_DIRECTORY_MODERATION_LISTING_ERROR";

export const IPB_DIRECTORY_MODERATION_DETAIL =
    "IPB_DIRECTORY_MODERATION_DETAIL";
export const IPB_DIRECTORY_MODERATION_DETAIL_ERROR =
    "IPB_DIRECTORY_MODERATION_DETAIL_ERROR";
export const CLEAN_IPB_DIRECTORY_MODERATION_DETAIL =
    "CLEAN_IPB_DIRECTORY_MODERATION_DETAIL";
export const CLEAN_IPB_DIRECTORY_MODERATION_DETAIL_ERROR =
    "CLEAN_IPB_DIRECTORY_MODERATION_DETAIL_ERROR";

export const HANDLE_STORE_MODERATION = "HANDLE_STORE_MODERATION";
export const HANDLE_STORE_MODERATION_ERROR = "HANDLE_STORE_MODERATION_ERROR";
export const CLEAN_HANDLE_STORE_MODERATION = "CLEAN_HANDLE_STORE_MODERATION";
export const CLEAN_HANDLE_STORE_MODERATION_ERROR =
    "CLEAN_HANDLE_STORE_MODERATION_ERROR";

export const REGISTERED_STORES = "REGISTERED_STORES";
export const REGISTERED_STORES_ERROR = "REGISTERED_STORES_ERROR";
export const CLEAN_REGISTERED_STORES = "CLEAN_REGISTERED_STORES";
export const CLEAN_REGISTERED_STORES_ERROR = "CLEAN_REGISTERED_STORES_ERROR";

export const EDIT_STORES = "EDIT_STORES";
export const EDIT_STORES_ERROR = "EDIT_STORES_ERROR";
export const CLEAN_EDIT_STORES = "CLEAN_EDIT_STORES";
export const CLEAN_EDIT_STORES_ERROR = "CLEAN_EDIT_STORES_ERROR";

export const DIRECTORY_STORES_EXISTS = "DIRECTORY_STORES_EXISTS";
export const DIRECTORY_STORES_EXISTS_ERROR = "DIRECTORY_STORES_EXISTS_ERROR";
export const CLEAN_DIRECTORY_STORES_EXISTS = "CLEAN_DIRECTORY_STORES_EXISTS";
export const CLEAN_DIRECTORY_STORES_EXISTS_ERROR =
    "CLEAN_DIRECTORY_STORES_EXISTS_ERROR";

export const DIRECTORY_STORES_OMVS = "DIRECTORY_STORES_OMVS";
export const DIRECTORY_STORES_OMVS_ERROR = "DIRECTORY_STORES_OMVS_ERROR";
export const CLEAN_DIRECTORY_STORES_OMVS = "CLEAN_DIRECTORY_STORES_OMVS";
export const CLEAN_DIRECTORY_STORES_OMVS_ERROR =
    "CLEAN_DIRECTORY_STORES_OMVS_ERROR";

export const ZIPCODE_ADDRESS_STORE = "ZIPCODE_ADDRESS_STORE";
export const ZIPCODE_ADDRESS_STORE_ERROR = "ZIPCODE_ADDRESS_STORE_ERROR";
export const CLEAN_ZIPCODE_ADDRESS_STORE = "CLEAN_ZIPCODE_ADDRESS_STORE";
export const CLEAN_ZIPCODE_ADDRESS_STORE_ERROR =
    "CLEAN_ZIPCODE_ADDRESS_STORE_ERROR";

export const FETCH_NEIGHBORHOOD_LOCATION = "FETCH_NEIGHBORHOOD_LOCATION";
export const FETCH_NEIGHBORHOOD_LOCATION_ERROR =
    "FETCH_NEIGHBORHOOD_LOCATION_ERROR";
export const CLEAN_NEIGHBORHOOD_LOCATION = "CLEAN_NEIGHBORHOOD_LOCATION";
export const CLEAN_NEIGHBORHOOD_LOCATION_ERROR =
    "CLEAN_NEIGHBORHOOD_LOCATION_ERROR";

export const SIM_GIFT_ORDERS = "SIM_GIFT_ORDERS";
export const SIM_GIFT_ORDERS_ERROR = "SIM_GIFT_ORDERS_ERROR";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const RESEND_EMAIL = "RESEND_EMAIL";

export const PRE_REGISTER_SIM_GIFT_IPB_ERROR =
    "PRE_REGISTER_SIM_GIFT_IPB_ERROR";
export const PRE_REGISTER_SIM_GIFT_IPB = "PRE_REGISTER_SIM_GIFT_IPB";
export const CLEAN_PRE_REGISTER_GIFT_SIM_IPB =
    "CLEAN_PRE_REGISTER_GIFT_SIM_IPB";
export const CLEAN_PRE_REGISTER_GIFT_SIM_IPB_ERROR =
    "CLEAN_PRE_REGISTER_GIFT_SIM_IPB_ERROR";
