import React, { useState, useEffect } from "react";
import { Grid2 as Grid, IconButton } from "@mui/material";
import "./styles/test-button-banner.css";

const TestButtonBanner = ({ appEnv }) => {
    const [position, setPosition] = useState({
        x: window.innerWidth - 150,
        y: 50,
    });

    const handleDragStart = (e) => {
        const clientX =
            e.type === "mousedown" ? e.clientX : e.touches[0].clientX;
        const clientY =
            e.type === "mousedown" ? e.clientY : e.touches[0].clientY;
        const offsetX = clientX - position.x;
        const offsetY = clientY - position.y;

        const handleDragMove = (moveEvent) => {
            const clientX =
                moveEvent.type === "mousemove"
                    ? moveEvent.clientX
                    : moveEvent.touches[0].clientX;
            const clientY =
                moveEvent.type === "mousemove"
                    ? moveEvent.clientY
                    : moveEvent.touches[0].clientY;

            let newX = clientX - offsetX;
            let newY = clientY - offsetY;

            newX = Math.max(0, Math.min(newX, window.innerWidth - 150));
            newY = Math.max(0, Math.min(newY, window.innerHeight - 150));

            setPosition({
                x: newX,
                y: newY,
            });
        };

        const handleDragEnd = () => {
            window.removeEventListener("mousemove", handleDragMove);
            window.removeEventListener("mouseup", handleDragEnd);
            window.removeEventListener("touchmove", handleDragMove);
            window.removeEventListener("touchend", handleDragEnd);
        };

        window.addEventListener("mousemove", handleDragMove);
        window.addEventListener("mouseup", handleDragEnd);
        window.addEventListener("touchmove", handleDragMove);
        window.addEventListener("touchend", handleDragEnd);
    };

    useEffect(() => {
        const handleResize = () => {
            setPosition((prevPos) => ({
                x: Math.min(window.innerWidth - 150, Math.max(0, prevPos.x)),
                y: Math.min(window.innerHeight - 150, Math.max(0, prevPos.y)),
            }));
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            className="floating-button"
            style={{ left: position.x, top: position.y }}
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
        >
            <IconButton
                style={{
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    backgroundColor: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid container>
                    <Grid size={12}>
                        <span
                            style={{
                                color: "white",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            {appEnv.toUpperCase()} &nbsp;
                        </span>
                    </Grid>
                    <Grid size={12} sx={{ paddingBottom: 2 }}>
                        <span
                            style={{
                                color: "white",
                                fontSize: 10,
                                textDecoration: "underline",
                            }}
                        >
                            NO - PRODUCTIVO
                        </span>
                    </Grid>
                </Grid>
            </IconButton>
        </div>
    );
};

export default TestButtonBanner;
