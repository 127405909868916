import React from "react";
import List from "@mui/material/List";
import { NavLink } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const GenericNavListItem = ({
    userPermissions,
    permission,
    to,
    title,
    IconComponent,
    activeStyle,
}) => {
    if (!permission) {
        return null;
    }

    const permissions = permission
        .split(",")
        .map((p) => p.trim())
        .filter((p) => p !== ""); // Divide los permisos por comas, elimina los espacios en blanco y filtra los valores vacíos

    // Verifica si el usuario tiene al menos uno de los permisos
    const hasPermission = permissions.some((p) => userPermissions?.includes(p));

    // Renderiza el componente solo si el usuario tiene al menos uno de los permisos
    return hasPermission ? (
        <List
            component="div"
            disablePadding
            sx={{ backgroundColor: "#f5f0fe" }}
        >
            <NavLink
                to={to}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                title={title}
            >
                <ListItemButton xs={{ pl: 4 }}>
                    <ListItemIcon>
                        <IconComponent />
                    </ListItemIcon>
                    <ListItemText
                        primary={title}
                        sx={{
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                        }}
                    />
                </ListItemButton>
            </NavLink>
        </List>
    ) : null;
};

export default GenericNavListItem;
